
const stringUtil = require('../string/stringUtil');
const PaymentMethods = require('../constants/paymentMethodConstants');

module.exports.numericThresholdValue = 0.0009;      // less than 0.001 = 0

module.exports.isCloseToZero = (value) => Math.abs(value) < this.numericThresholdValue;

module.exports.LookupCode_VenueCode_Property = "Property";

module.exports.applicationModes = {
    normal: "normal",
    stationActivationWizard: "stationActivationWizard",
    embedded: "embedded"
}

module.exports.printerEncodings = 
{
    sunmi: {
        encodingType:"UTF-8",
        encodingInstructions:"28,67,255",
    }
}

module.exports.deliveryManagement = 
{
    shipDay: "ShipDay"
}

module.exports.smsProviders =
{
    nexmo: "Nexmo", 
}

module.exports.getSmsProviders = function ()
{
    return Object.keys(this.smsProviders).sort().map(smsProvider => ({value: this.smsProviders[smsProvider]}))
}

module.exports.storePayoutIntegrationTypes =
{
    flipdish: "Flipdish"
}

module.exports.getStorePayoutIntegrationTypes = function ()
{
    return Object.keys(this.storePayoutIntegrationTypes).sort().map(storePayoutIntegrationType => ({value: this.storePayoutIntegrationTypes[storePayoutIntegrationType]}))
}

module.exports.AllergyPresenceType =
{
    unknown: "Unknown",
    notPresent: "NotPresent",
    present: "Present",
    mayBe: "MayBe"
}

module.exports.NutritionCode =
{
    // Referred to by calorieCalculator
    carbohydrates: "Carbohydrates",
    protein: "Protein",
    fats: "Fats"
}

module.exports.getOpenCashDrawerPaymentMethods = function ()
{
    const excludedPaymentMethods = [PaymentMethods.venuePaid, PaymentMethods.both, PaymentMethods.NA];

    return PaymentMethods.options
        .filter((option) => !excludedPaymentMethods.includes(option.paymentMethod))
        .sort((option1, option2) => option1.paymentMethod > option2.paymentMethod);
}

module.exports.saleTypes =
{
    sale: 'Sale',
    staffMeal: 'Staff.Meal',
    guestsMeal: 'Guests.Meal',
    freebiesMeal: 'Freebies.Meal'
}

module.exports.dispatchTypes =
{
    dineIn: "DineIn",
    takeAway: "TakeAway",
    collection: "Collection",
    delivery: "Delivery"
}

module.exports.getDispatchTypes = function ()
{
    return Object
        .keys(this.dispatchTypes)
        .sort()
        .map(dispatchType => (
            {
                dispatchType: this.dispatchTypes[dispatchType]
            }));
}

module.exports.MarketplaceStorefronts =
{
    UberEats: 'UberEats',
    JustEats: 'JustEats',
    Deliveroo: 'Deliveroo',
    ExternalApp: 'ExternalApp'
}

module.exports.PropertyStorefronts =
{
    POS: 'POS',
    KIOSK: 'KIOSK',
    MobileApp: 'MobileApp',
    WebApp: 'WebApp',
}

module.exports.FlipdishStorefronts =
{
    FlipdishKIOSK: 'FlipdishKIOSK',
    FlipdishMobileApp: 'FlipdishMobileApp',
    FlipdishWebApp: 'FlipdishWebApp',
}

module.exports.storefrontTypes = Object.assign({}, module.exports.MarketplaceStorefronts,
    module.exports.PropertyStorefronts,
    module.exports.FlipdishStorefronts);

module.exports.getStorefrontTypes = function()
{
    var types = [];

    var type = {};
    type.value = this.storefrontTypes.POS;
    type.label = "POS";
    types.push(type);

    type = {};
    type.value = this.storefrontTypes.UberEats;
    type.label = "UberEats";
    types.push(type);

    type = {};
    type.value = this.storefrontTypes.JustEats;
    type.label = "JustEats";
    types.push(type);

    type = {};
    type.value = this.storefrontTypes.Deliveroo;
    type.label = "Deliveroo";
    types.push(type);

    type = {};
    type.value = this.storefrontTypes.KIOSK;
    type.label = "KIOSK";
    types.push(type);

    type = {};
    type.value = this.storefrontTypes.MobileApp;
    type.label = "Mobile App";
    types.push(type);

    type = {};
    type.value = this.storefrontTypes.WebApp;
    type.label = "Web App";
    types.push(type);

    type = {};
    type.value = this.storefrontTypes.FlipdishKIOSK;
    type.label = "Flipdish KIOSK";
    types.push(type);

    type = {};
    type.value = this.storefrontTypes.FlipdishMobileApp;
    type.label = "Flipdish Mobile App";
    types.push(type);

    type = {};
    type.value = this.storefrontTypes.FlipdishWebApp;
    type.label = "Flipdish Web App";
    types.push(type);

    type = {};
    type.value = this.storefrontTypes.ExternalApp;
    type.label = "External App";
    types.push(type);

    return types;
}

module.exports.webAppModes =
{
    OnlineOrderingApp: 'OnlineOrderingApp',
    TableApp: 'TableApp',    
}

module.exports.getWebAppModes = function()
{
    var modes = [];

    var type = {};
    type.value = this.webAppModes.OnlineOrderingApp;
    type.label = "Online Ordering App";
    modes.push(type);

    type = {};
    type.value = this.webAppModes.TableApp;
    type.label = "Table App";
    modes.push(type);

    return modes;
}

module.exports.webAppOnlinePaymentProviders =
{
    Stripe: 'Stripe',
    StripeOverPhone: 'StripeOverPhone'
}

module.exports.mobileAppOnlinePaymentProviders =
{
    BrainTree: 'BrainTree'
}

module.exports.brainTreeEnvironments =
{
    Production: "Production",
    Sandbox: "Sandbox"
}

module.exports.paymentMethods =
{
    Cash: "Cash",
    Credit: "Credit",
    VenuePaid: "VenuePaid",
    Both: "Both",
    NA: "NA"
}

module.exports.venueCodes =
{
    Deliveroo: 'Deliveroo',
    JustEats: 'JustEats',
    UberEats: 'UberEats',
    Flipdish: 'Flipdish',
    Property: 'Property',
    ExternalApp: 'ExternalApp',
}

module.exports.getVenueCodes = function ()
{
    return Object
        .keys(this.venueCodes)
        .sort()
        .map(venueCode => (
            {
                venueCode: this.venueCodes[venueCode]
            }));
}

module.exports.getExternalChannelVenueCodes = function()
{
    return [this.venueCodes.Deliveroo, this.venueCodes.JustEats, this.venueCodes.UberEats, this.venueCodes.ExternalApp];
}

module.exports.channels =
{
    UrbanPiper: 'UrbanPiper',
    Deliverect: 'Deliverect',
    Flipdish: 'Flipdish',
    MobileApp: 'MobileApp',
    POS: 'POS',
    KIOSK: 'KIOSK',
    JustEatDirect: 'JustEatDirect',
}

module.exports.getChannels = function ()
{
    return Object
        .keys(this.channels)
        .sort()
        .map(channel => (
            {
                channel: this.channels[channel]
            }));
}

module.exports.chargesTypes =
{
    VenueServiceCharges: "Venue.Service.Charges",
    VenueDeliveryCharges: "Venue.Delivery.Charges"
}

module.exports.triStateFlag =
{
    always: 'always',
    never: 'never',
    optional: 'optional',
}

module.exports.getTriStateFlags = function ()
{
    return Object
        .keys(this.triStateFlag)
        .sort()
        .map(flag => (
            {
                key: this.triStateFlag[flag],
                value: stringUtil.capitalize(this.triStateFlag[flag])
            }));
}

module.exports.attendanceAccess =
{
    never: 'never',
    draft: 'draft',
    published: 'published'
}

module.exports.getAttendanceAccessFlags = function ()
{
    return Object
        .keys(this.attendanceAccess)
        .sort()
        .map(flag => (
            {
                key: this.attendanceAccess[flag],
                value: stringUtil.capitalize(this.attendanceAccess[flag])
            }));
}

module.exports.kitchenStationTypes =
{
    //
    // Used in Kitchen Station Profiles Setup
    //

    stationTypeMonitor: "Monitor",
    stationTypePrinter: "Printer"
};

module.exports.getKitchenStationTypes = function ()
{
    return Object
        .keys(this.kitchenStationTypes)
        .sort()
        .map(key => ({ displayFieldName: this.kitchenStationTypes[key], valueFieldName: this.kitchenStationTypes[key] }));
}

module.exports.tagTypes =
{
    //
    // Following are hard coded tag types used by Tags lookup data
    //

    KDS: "KDS",
    KDSCourseName: "KDS Course Name",
    Discount: "Discount",
    Report: "Report",
    Snooze: "Snooze"
};


module.exports.getTagTypes = function ()
{
    return Object
        .keys(this.tagTypes)
        .sort()
        .map(key => ({ displayFieldName: this.tagTypes[key], valueFieldName: this.tagTypes[key] }));
}

module.exports.propertyNames = {

    //
    // Define property name constants here to avoid using magic strings
    //

    discountExclusionTags: "exclusionTags"       // Discount offers exclusion tags
}

module.exports.DiscountOfferTypes =
{
    Fixed: 'Fixed',
    Percentage: 'Percentage'
}

module.exports.getDiscountOfferTypes = function ()
{
    var options = [];

    var option = {};
    option.value = this.DiscountOfferTypes.Percentage;
    option.label = "Percentage (%)";
    options.push(option);

    option = {};
    option.value = this.DiscountOfferTypes.Fixed;
    option.label = "Fixed Amount";
    options.push(option);

    return options;
}

module.exports.propertyMenuTypes =
{
    Primary: 'Primary',
    Reward: 'Reward',
    Kiosk: 'Kiosk'
}

module.exports.campaignType =
{
    SMS: 'SMS',
    App: 'App'
}

module.exports.highlightTerms = [
    "%name%"
]

module.exports.floatManagementJournalEntryType =
{
    Withdraw: 'Withdraw',
    Deposit: 'Deposit',
}

module.exports.floatManagementJournalEntrySubType =
{
    TransferToSafe: 'TransferToSafe',
    Expense: 'Expense',
    ChangeGiven: 'ChangeGiven',

    OpeningBalance: 'OpeningBalance',
    TransferFromSafe: 'TransferFromSafe',
    ChangeReturned: 'ChangeReturned',
}

module.exports.floorStencilType =
{
    Seating: 'Seating',
    Plants: 'Plants',
}

module.exports.blogTypes =
{
    SignagePlayers: "signage.players",
    KioskSkins: "kiosk.skins"
}

module.exports.signagePlayerType = {
    Slides: "Slides",
    ActiveOrders: "ActiveOrders"
}

module.exports.signageSlideTypes =
{
    Weather: "weather",
    Image: "image",
    ActiveOrders: "activeOrders",
    Video: "video"
}

module.exports.signageSlideVisibility =
{
    Hidden: "Hidden",
    Always: "Always",
    WithActiveOrder: "Active.Order",
    WithoutActiveOrder: "No.Active.Order"
}

module.exports.voucherCodeType =
{
    percentage: 'percentage',
    fixed: 'fixed'
}

module.exports.getVoucherTypes = function ()
{
    var options = [];

    var option = {};
    option.value = this.voucherCodeType.percentage;
    option.label = "Percentage (%)";
    options.push(option);

    option = {};
    option.value = this.voucherCodeType.fixed;
    option.label = "Fixed Amount";
    options.push(option);

    return options;
}

module.exports.voucherCodeClaimType =
{
    oneTimeOnlyCustomer: 'oneTimeOnly.Customers',
    oneTimeOnlyNewCustomer: 'oneTimeOnly.NewCustomers',
    oneTimeOnlyGuest: 'oneTimeOnly.Guests',

    multipleTimesCustomer: 'multipleTimes.Customers',
    multipleTimesGuest: 'multipleTimes.Guests',
}

module.exports.getVoucherClaimTypes = function ()
{
    var options = [];

    var option = {};
    option.value = this.voucherCodeClaimType.oneTimeOnlyGuest
    option.label = "One Time Only (Guest)";
    options.push(option);

    option = {};
    option.value = this.voucherCodeClaimType.oneTimeOnlyCustomer
    option.label = "One Time Only (Customer)";
    options.push(option);

    option = {};
    option.value = this.voucherCodeClaimType.oneTimeOnlyNewCustomer
    option.label = "One Time Only (New Customer)";
    options.push(option);

    option = {};
    option.value = this.voucherCodeClaimType.multipleTimesGuest
    option.label = "Multiple Times (Guest)";
    options.push(option);

    option = {};
    option.value = this.voucherCodeClaimType.multipleTimesCustomer
    option.label = "Multiple Times (Customer)";
    options.push(option);

    return options;
}

module.exports.CharitySchemeTypes =
{
    roundUp: "RoundUp",
    fixedAmount: "FixedAmount"
}

module.exports.getCharitySchemeTypes = function ()
{
    return Object
        .keys(this.CharitySchemeTypes)
        .sort()
        .map(charityType => (
            {
                charityType: this.CharitySchemeTypes[charityType]
            }));
}

module.exports.HazardType =
{
    quality: "Quality",
    safety: "Safety",
    operational: "Operational",
    health: "Health"
}

module.exports.minTemperatureLogType =
{
    fixed: "Fixed",
}

module.exports.getMinTemperatureLogTypes = () =>
    Object.values(this.minTemperatureLogType)
        .map(typeValue => ({ value: typeValue, label: typeValue }));

module.exports.getHazardTypeOptions = () =>
    Object.values(this.HazardType)
        .map(hazardType => ({ value: hazardType, label: `${hazardType} Hazard` }));

        
module.exports.UpdateSourceType =
{
    staff: "Staff"
}

module.exports.PurchaseOrderStatusType =
{
    draft: "Draft",
    submitted: "Submitted",
    received: "Received",
    voided: "Voided"
}

module.exports.CentralKitchenSaleStatusType =
{
    open: "Open",
    approved: "Approved",
    dispatched: "Dispatched",
    cancelled: "Cancelled",
}

module.exports.CentralKitchenSaleItemStatusType =
{
    open: "Open",
    completed: "Completed",
}

module.exports.TimeZones = [
    // IANA Time Zone Identifiers
    // Etc/GMT is (one of several) IANA time zone identifier for UTC
    {
        value: "Europe/London",
        label: "Europe/London"
    },
    {
        value: "Europe/Dublin",
        label: "Europe/Dublin"
    },
    {
        value: "Asia/Karachi",
        label: "Pakistan Standard Time"
    },
    {
        value: "Africa/Nairobi",
        label: "Africa/Nairobi"
    },
    {
        value: "America/New_York",
        label: "Eastern Time"
    },
    {
        value: "America/Chicago",
        label: "Central Time"
    },
    {
        value: "America/Denver",
        label: "Mountain Time"
    },
    {
        value: "America/Los_Angeles",
        label: "Pacific Time"
    },
    {
        value: "Europe/Madrid",
        label: "Central European Summer Time"
    }
]

module.exports.stockItemOperationTypes = {

    // System defined operationTypes, used in defining operationalUnitTypes/reportingUnitTypes

    wastage: "Wastage",
    inventoryTracking: "InventoryTracking",
    reporting: "Reporting",
    discrepancyReporting: "DiscrepancyReporting",
    buildPlan: "BuildPlan"
}

module.exports.AlertType =
{
    primary: "primary",
    secondary: "secondary",
    info: "info",
    success: "success",
    warning: "warning",
    danger: "danger"
}

module.exports.TerminalTypes =
{
    pos: "pos",
    kds: "kds",
    hardware: "hardware",
    kiosk: "kiosk",
    kitchen: "kitchen",
    signage: "signage",
}

module.exports.dataTypes = {
    string: "string",
    number: "number",
    boolean: "boolean",
    enumeration: "enumeration"
}

module.exports.creditTypes = {
    sms: "sms"
}

module.exports.getCreditTypeOptions = function ()
{
    return Object
        .keys(this.creditTypes)
        .sort()
        .map(creditType => (
            {
                label: this.creditTypes[creditType],
                value: this.creditTypes[creditType]
            }));
}

module.exports.incidentTypeQuestionResponseTypes =
{
    boolean: "Boolean",
    text: "Text",
    options: "Options",
}

module.exports.serviceContextTypes =
{
    // Used by "type" attribute on property, category, stockItem, supplier, menu, trackingProfile
    store: "Store",
    centralKitchen: "CentralKitchen"
}

module.exports.printingTemplateTypes = 
{
    receipt: "receipt",
    kitchenStation: "kitchenStation",
    voucher: "voucher",
    dailySummaryReport: "dailySummaryReport",
    zplItemLabelTemplate: "zpl.item.label",
    zplOrderLabelTemplate: "zpl.order.label",
    zplKotTemplate: "zpl.kot.label",
    zplExpiryTemplate: "zpl.expiry.label"
}

module.exports.selfServiceRequestStatus =
{
    pending: "pending",
    rejected: "rejected",
    approved: "approved",
}

module.exports.Charges = {
    types: {
        percentage: "Percentage", fixed: "Fixed"
    },
    targets: {
        order: "Order", 
        item: "Item"
    },
    schemes: {
        serviceCharge: "Service.Charge", 
        depositReturn: "Deposit.Return", 
        bagCharge: "Bag.Charge", 
        tipCharge: "Tip.Charge",
        other: "Other"
    }
}

module.exports.ReceiptLineTypes =
{
    Charge: 'Charge',
    Refund: 'Refund',
}

module.exports.OpeningHoursType =
{
    allDayOpen: "allDayOpen",
    allDayClose: "allDayClose",
    timeSlot: "timeSlot"
}

module.exports.rmsExternalClientAssociationTypes = {
    franchisor: "franchisor",
    franchisee: "franchisee",
    property: "property",
    storefront: "storefront",
    brand: "brand",
}

module.exports.rmsExternalClientSystemNames = {
    flipdish: "flipdish"
}